import { Box, Grid, Typography } from '@mui/material'
import { type FC } from 'react'
import './About.css'
import Heading from './Heading'
import type { ExperienceItem } from './types'

const experiences: ExperienceItem[] = [
  {
    institution: 'Raleigh Civic Symphony and Chamber Orchestra',
    subString: 'Violinist',
    description:
      'Rotating seating; regularly perform as Principal (Second Violin) or Second Chair (First Violin)',
    startDate: '2016',
    endDate: 'Present'
  },
  {
    institution: 'Freelance Violinist',
    description: 'Classical violin and fiddle music in the Raleigh, NC area',
    startDate: '2015',
    endDate: 'Present'
  },
  {
    institution: 'Wake Technical Community College',
    subString: 'Instructor of Music',
    description: 'Instructor for Music Appreciation and Introduction to Jazz',
    startDate: '2015',
    endDate: '2018'
  },
  {
    institution: 'Sligo Line',
    subString: 'Music Director and Fiddle',
    description: 'An Irish traditional band based in Tallahassee, FL',
    startDate: '2013',
    endDate: '2015'
  },
  {
    institution: 'Florida State University',
    subString: 'Instructor of Record',
    description: 'Instructor for Music History courses in the Music Major program',
    startDate: '2012',
    endDate: '2015'
  },
  {
    institution: 'Albany Symphony Orchestra',
    subString: 'Substitute Violinist',
    description: 'Served as a violin substitute in Albany, GA',
    startDate: '2012'
  },
  {
    institution: 'The Ned Devines',
    subString: 'Fiddle',
    description: 'An Irish traditional band based in Tallahassee, FL',
    startDate: '2011',
    endDate: '2013'
  },
  {
    institution: 'Big Bend Community Orchestra',
    description: 'Concertmaster',
    startDate: '2011',
    endDate: '2015'
  }
]

const Experience: FC = () => {
  const dateString = (start?: string, end?: string) => {
    if (end != null) {
      return `${start} - ${end}`
    }
    return start
  }

  return (
    <Box className='experience'>
      <Heading heading='Experience:' />
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 2, sm: 2 }}>
        {experiences.map(({ institution, description, startDate, endDate, subString }) => (
          <>
            <Grid sx={{ textAlign: 'right' }} xs={1} item key={institution}>
              <Typography dangerouslySetInnerHTML={{ __html: institution }}></Typography>
              {subString != null ? <Typography variant='subtitle2'>{subString}</Typography> : null}
              <Typography className='sub-title' variant='subtitle2'>
                {dateString(startDate, endDate)}
              </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'left' }} xs={1} item key={description}>
              <Typography>{description}</Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  )
}

export default Experience
