import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import About from './About'
import Contact from './Contact'
import Home from './Home'
import Layout from './Layout'

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/contact',
        element: <Contact />
      }
    ]
  }
])

const App = () => {
  return <RouterProvider router={router} />
}

export default App
