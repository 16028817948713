import { Typography } from '@mui/material'
import type { FC } from 'react'
import AboutLink from './AboutLink'
import Heading from './Heading'

const MainAbout: FC = () => {
  return (
    <>
      <Heading heading='About Catherine:' />
      <Typography
        className='description'
        margin={'auto'}
        maxWidth='800px'
        variant='body1'
        component='div'
        align='justify'
      >
        Catherine Hall is a multi-faceted violinist. She holds a bachelor&apos;s degree in classical
        violin performance from Appalachian State University, along with a master&apos;s degree and
        doctor of philosophy in musicology from Florida State University. She has nearly seventeen
        years of experience playing Irish traditional fiddle. She was a member of two Irish
        traditional bands in Tallahassee, FL:{' '}
        <AboutLink
          link='https://open.spotify.com/album/4d0VtOmBliiuMcWdUbELtJ?si=IxmmliExQhigs9jFBWV2lw'
          title='The Ned Devines'
        />{' '}
        and{' '}
        <AboutLink
          link='https://sligoline.bandcamp.com/album/leaving-connolly-station'
          title='Sligo Line'
        />
        . For the past eight years, she has played with the Raleigh Civic Symphony and Chamber
        Orchestra based at NCSU, along with performing at weddings, church services, holiday
        celebrations, and other performances in the Raleigh, NC area.
      </Typography>
    </>
  )
}

export default MainAbout
