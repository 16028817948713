import { Box, Divider } from '@mui/material'
import './About.css'
import Education from './Education'
import Experience from './Experience'
import MainAbout from './MainAbout'

const About = () => {
  return (
    <Box className='container-about'>
      <div className='container-item-about' style={{ padding: '0 0 0 0' }}>
        <div id='image-about'></div>
      </div>
      <div className='container-item-about'>
        <MainAbout />
        <Divider sx={{ p: 1 }} />
        <Education />
        <Divider sx={{ p: 1 }} />
        <Experience />
      </div>
    </Box>
  )
}

export default About
