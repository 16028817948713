import { Grid, Typography } from '@mui/material'
import type { FC } from 'react'
import './About.css'
import Heading from './Heading'
import type { ExperienceItem } from './types'

const ed: ExperienceItem[] = [
  {
    institution: 'Florida State University',
    description: 'PhD: Musicology',
    endDate: '2020'
  },
  {
    institution: 'Florida State University',
    description: 'Master of Music: Historical Musicology',
    endDate: '2012'
  },
  {
    institution: 'Appalachian State University',
    description: 'Bachelor of Music: Violin Performance, History Minor',
    endDate: '2010'
  }
]

const Education: FC = () => {
  return (
    <>
      <Heading heading='Education:' />
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 2, sm: 2 }}>
        {ed.map(({ institution, description, endDate }) => (
          <>
            <Grid sx={{ textAlign: 'right' }} xs={1} item key={institution}>
              {institution}
            </Grid>
            <Grid sx={{ textAlign: 'left' }} xs={1} item key={description}>
              <Typography dangerouslySetInnerHTML={{ __html: description }}></Typography>
              <Typography className='sub-title' variant='subtitle2'>
                {endDate}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  )
}

export default Education
