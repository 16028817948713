import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from './NavBar'

const theme = createTheme({
  palette: { mode: 'dark' },
  typography: {
    fontFamily: 'Arial'
  }
})

const Layout: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Outlet />
    </ThemeProvider>
  )
}

export default Layout
