import { Link } from '@mui/material'
import type { FC } from 'react'

interface AboutLinkProps {
  readonly link: string
  readonly title: string
}

const AboutLink: FC<AboutLinkProps> = ({ link, title }) => {
  return (
    <Link
      className='hover-underline-animation'
      rel='noopener noreferrer'
      underline='none'
      href={link}
      target='_blank'
      color={'#2a623d'}
    >
      {title}
    </Link>
  )
}
export default AboutLink
