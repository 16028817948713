import { Typography } from '@mui/material'
import type { FC } from 'react'
interface HeadingProps {
  heading: string
}
const Heading: FC<HeadingProps> = ({ heading }) => {
  return (
    <Typography
      variant='h4'
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
      component='div'
    >
      <div className='title'>{heading}</div>
    </Typography>
  )
}

export default Heading
