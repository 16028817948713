import { Box, Link, Typography } from '@mui/material'
import type { FC } from 'react'
import './Contact.css'

interface MailLinkProps {
  readonly variant?: 'lessons' | 'gig' | 'collaboration' | 'tutor'
}

const MailLink: FC<MailLinkProps> = ({ variant }) => {
  let href = ''
  let text = ''

  switch (variant) {
    case 'lessons':
      href = 'mailto:catherinecwhall+lessons@gmail.com?subject=Lessons&body=Hello, Catherine!'
      text = 'violin lessons'
      break
    case 'gig':
      text = 'performance'
      href =
        'mailto:catherinecwhall+performance@gmail.com?subject=Performance&body=Hello, Catherine!'
      break
    case 'collaboration':
      text = 'music-making'
      href = 'mailto:catherinecwhall+collab@gmail.com?subject=Collaboration&body=Hello, Catherine!'
      break
    case 'tutor':
      text = 'tutoring'
      href = 'mailto:catherinecwhall+tutor@gmail.com?subject=Tutor&body=Hello, Catherine!'
      break
    default:
      text = 'catherinecwhall@gmail.com'
      href = 'mailto:catherinecwhall@gmail.com?body=Hello, Catherine!'
  }

  return (
    <Link href={href} className='hover-underline-animation' underline='none' color={'#2a623d'}>
      {text}
    </Link>
  )
}

const Contact = () => {
  return (
    <Box className='container-c'>
      <Box className='container-item-c'>
        <div id='image-c'></div>
      </Box>
      <Box className='contact-text'>
        <Typography margin={'auto'} variant='h3' component={'div'}>
          Let&apos;s Connect!
        </Typography>
        <Typography
          margin={'auto'}
          component='div'
          className='description'
          maxWidth='800px'
          variant='h6'
          align='justify'
        >
          If you are interested in <MailLink variant='lessons' />, hiring me for a{' '}
          <MailLink variant='gig' />, music history <MailLink variant='tutor' />, or collaborative{' '}
          <MailLink variant='collaboration' />, please send me a message and let&apos;s talk:{' '}
          <MailLink />.
        </Typography>
      </Box>
    </Box>
  )
}

export default Contact
