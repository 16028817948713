import { Box } from '@mui/material'
import { useState } from 'react'
import { TypeAnimation } from 'react-type-animation'
import './Home.css'

const Home = () => {
  const [lowerVisible, setLowerVisible] = useState(false)

  return (
    <div id='bg-image'>
      <Box className='container'>
        <Box className='container-item'></Box>
        <Box className='container-item'>
          <TypeAnimation
            sequence={[
              'Catherine Hall',
              250,
              () => {
                setLowerVisible(!lowerVisible)
              }
            ]}
            wrapper='h1'
            speed={1}
            style={{ fontSize: '3em', display: 'inline-block', textShadow: '#FF00 1px 0 10px' }}
            cursor={false}
          />
          <Box sx={{ minHeight: '100px' }}>
            {lowerVisible && (
              <TypeAnimation
                sequence={[
                  'Violinist',
                  500,
                  'Instructor',
                  500,
                  'Musicologist',
                  500,
                  'Irish Traditional Fiddler',
                  500
                ]}
                wrapper='span'
                speed={1}
                deletionSpeed={75}
                style={{ fontSize: '2em', display: 'inline-block', textShadow: '#FF00 1px 0 10px' }}
                repeat={Infinity}
                cursor={false}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Home
